import { findPathDeep, findValueDeep } from 'deepdash/standalone';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

/**
 * Позволяет найти путь до искомого свойства по ключу
 * @param object {object} - объект, в котором нужно найти значение свойства
 * @param key {string} - ключ искомого свойства
 * @return {string} - путь
 */
export const findPathDeepByKey = (object, key) => findPathDeep(
  object,
  (objectValue, objectKey) => String(objectKey) === String(key),
  {
    leavesOnly: false,
  },
);

/**
 * Позволяет найти путь до искомого свойства по значению
 * @param object {object} - объект, в котором нужно найти значение свойства
 * @param value {*} - значение искомого свойства
 * @return {*} - искомое значение
 */
export const findPathDeepByValue = (object, value) => findPathDeep(
  object,
  (objectValue) => isEqual(objectValue, value),
  {
    leavesOnly: false,
  },
);

/**
 * Позволяет найти значение свойства в объекте по ключу
 * @param object {object} - объект, в котором нужно найти значение свойства
 * @param key {string} - ключ искомого свойства
 * @return {*} - искомое значение
 */
export const getValueDeepByKey = (object, key) => findValueDeep(
  object,
  (objectValue, objectKey) => String(objectKey) === String(key),
  {
    leavesOnly: false,
  },
);

/**
 * Позволяет получить значение свойства по строчному пути
 * @param object {object} - объект, в котором нужно найти значение свойства
 * @param path {string} - путь до искомого свойства
 * @return {*} - искомое значение
 */
export const getValueByPath = (object, path) => get(object, path);

/**
 * Позволяет получить объект по значению одного из его ключей.
 * @param object {object} - объект, в котором нужно найти объект
 * @param value {*} - значение искомого свойства
 * @return {*} - искомый объект
 */
export const getObjectDeepByValue = (object, value) => {
  const pathToOption = findPathDeepByValue(object, value);

  if (!pathToOption) return undefined;
  const pathToValue = pathToOption.slice(0, pathToOption.lastIndexOf('.'));

  return getValueByPath(object, pathToValue);
};
